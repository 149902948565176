import ReactPixel from "react-facebook-pixel";

export function isScrolledIntoView(el) {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;
    return (elemTop >= 0) && (elemBottom <= window.innerHeight);
}

export function openLink(name, url) {
    ReactPixel.trackCustom(name);
    window.open(url, '_blank');
}

export function track(name, obj) {
    ReactPixel.trackCustom(name, obj);
}
