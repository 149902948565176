import React from "react";
import { Link } from "gatsby";

import { Container, Row, Col } from "common/src/reusecore/Layout";

import { FiMail } from "react-icons/fi";
import { FaTwitter, FaTelegramPlane } from "react-icons/fa";

import logo from "common/src/assets/images/app/footer/footer-logo.png";
import particleOne from "common/src/assets/images/app/footer/footer-particle.png";

import FotterWrapper from "./footer.style";
import { Trans } from '../../reusecore/Trans';

const Footer = () => {
  return (
    <FotterWrapper>
      <img className="section__particle one" alt="img" src={particleOne} alt="appion app landing" />
      <Container>
        <Row>
          <Col xs={12} sm={6} lg={3}>
            <div className="footer-widgets first">
              <Link className="footer-logo" to="#">
                <img src={logo} alt="logo" />
              </Link>
              <ul className="info">
                <li>
                  <FiMail />
                  <a className="anchor" to="mailto:contact@be.cash">
                    contact@be.cash
                  </a>
                </li>
              </ul>

              <ul className="social">
                <li>
                  <a className="anchor" href="https://t.me/be_cash">
                    <FaTelegramPlane />
                  </a>
                </li>
                <li>
                  <a className="anchor" href="https://twitter.com/TobiasRuck">
                    <FaTwitter />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <div className="footer-widgets">
            </div>
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <div className="footer-widgets">
              <h3 className="widget-title">
                <Trans i18nKey="footer.links" />
              </h3>
              <ul className="widget-catagory">
                <li>
                  <a className="anchor" href="becash.pdf">
                    <Trans i18nKey="navigation.whitepaper" />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div className="footer-bottom">
          <ul className="footer-menu">
            <li>
              <Link className="anchor" to="privacy">
                <Trans i18nKey="footer.privacy" />
              </Link>
            </li>
          </ul>
          <p className="copyright-text">
            <Trans i18nKey="footer.copyright" />
          </p>
        </div>
      </Container>
    </FotterWrapper>
  );
};

export default Footer;
